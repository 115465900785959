import { PeopleInterface } from '../reducers/types';

import { peopleImages } from '../assets';

const people: PeopleInterface[] = [
    {
        id: "0",
        name: 'TBA',
        image: peopleImages['tba'],
        affiliation: '',
        bio: `Our exciting panel of experts will be announced soon. Stay tuned for updates!`,
    },
    {
        id: "1",
        name: 'Chelsea Voss',
        image: peopleImages['chelsea-voss'],
        affiliation: 'OpenAi',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "3",
        name: 'Tess van Stekelenburg',
        image: peopleImages['tess-van-stekelenburg'],
        affiliation: 'Lux Capital',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "4",
        name: 'Niccolo Zanchelli',
        image: peopleImages['niccolo-zanchelli'],
        affiliation: 'OpenBioML',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "5",
        name: 'Katie Link',
        image: peopleImages['katie-link'],
        affiliation: 'HuggingFace',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "6",
        name: 'Alfredo Andere',
        image: peopleImages['alfredo-andere'],
        affiliation: 'LatchBio',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "7",
        name: 'Anton Troynikov',
        image: peopleImages['anton-troynikov'],
        affiliation: 'Chroma',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "8",
        name: 'Lan Jiang',
        image: peopleImages['lang-jiang'],
        affiliation: 'Lux Capital',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "9",
        name: 'Joshua Meier',
        image: peopleImages['joshua-meier'],
        affiliation: 'AbSci',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    }, {
        id: "10",
        name: 'Oren Kraus',
        image: peopleImages['oren-kraus'],
        affiliation: 'Recursion Pharmaceuticals',
        bio: `Recursion Foundation Model`,
    }, {
        id: "11",
        name: 'David Healey',
        image: peopleImages['david-healey'],
        affiliation: 'Enveda Biosciences',
        bio: `Enveda Foundation Model`,
    }, {
        id: "12",
        name: 'Christoph A. Krettler',
        image: peopleImages['christoph-krettler'],
        affiliation: 'Enveda Biosciences',
        bio: ``,
    }, {
        id: "13",
        name: 'Nicholas Larus-Stone',
        image: peopleImages['nicholas-larus-stone'],
        affiliation: 'Sphinx Bio',
        bio: ``,
    }, {
        id: "14",
        name: 'Hannah Le',
        image: peopleImages['hannah-lee'],
        affiliation: 'LatchBio',
        bio: ``,
    }, {
        id: "15",
        name: 'Neil Thomas',
        image: peopleImages['neil-thomas'],
        affiliation: 'EvolutionaryScale',
        bio: ``,
    }, {
        id: "16",
        name: 'John Cumbers',
        image: peopleImages['john-cumbers'],
        affiliation: 'SynBioBeta',
        bio: ``,
    }, {
        id: "17",
        name: 'Eric Zimmerman',
        image: peopleImages['eric-zimmerman'],
        affiliation: 'AWS',
        bio: ``,
    }, {
        id: "18",
        name: 'Berton Earnshaw',
        image: peopleImages['berton-earnshaw'],
        affiliation: 'Recursion | Valence',
        bio: ``,
    }
    , {
        id: "19",
        name: 'Charu Jaiswal',
        image: peopleImages['charu-jaiswal'],
        affiliation: 'OpenAI',
        bio: ``,
    }
    , {
        id: "20",
        name: 'Dominique Beaini',
        image: peopleImages['dominique-beaini'],
        affiliation: 'Polaris',
        bio: ``,
    }
    , {
        id: "21",
        name: 'Kevin Yang',
        image: peopleImages['kevin-yang'],
        affiliation: 'Microsoft',
        bio: ``,
    }
    , {
        id: "22",
        name: 'Julian Englert',
        image: peopleImages['julian-englert'],
        affiliation: 'Adaptyv Bio',
        bio: ``,
    }
    , {
        id: "23",
        name: 'Marius Wiggert',
        image: peopleImages['marius-wiggert'],
        affiliation: 'EvolutionaryScale',
        bio: ``,
    }
    , {
        id: "24",
        name: 'Noah MacCallum',
        image: peopleImages['noah-macCallum'],
        affiliation: 'OpenAI',
        bio: ``,
    }
    , {
        id: "25",
        name: 'Shyamal Anadkat',
        image: peopleImages['shyamal-hitesh-anadkat'],
        affiliation: 'OpenAI',
        bio: ``,
    }
    , {
        id: "26",
        name: 'Thomas Stanis',
        image: peopleImages['thomas-stanis'],
        affiliation: 'Story Health',
        bio: ``,
    }
    , {
        id: "27",
        name: 'Uri Laserson',
        image: peopleImages['uri-laserson'],
        affiliation: 'Ginkgo Bioworks',
        bio: ``,
    }
    , {
        id: "28",
        name: 'Vega Shah',
        image: peopleImages['vega-shah'],
        affiliation: 'NVIDIA',
        bio: ``,
    }, {
        id: "2",
        name: 'Michael Retchin',
        image: peopleImages['michael-retchin'],
        affiliation: 'Scale Medicine',
        bio: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu.`,
    },
]


export default people;