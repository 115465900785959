import { logos } from '../assets';
import { CompanyInterface } from '../reducers/types';

const companies: CompanyInterface[] = [
    {
        id: "0",
        name: 'Lux Capital',
        logo: logos['lux-capital'],
        link: 'https://www.luxcapital.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    },
    {
        id: "1",
        name: 'Hugging Face',
        logo: logos['huggingface'],
        link: 'https://huggingface.co/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "2",
        name: 'Nucleate',
        logo: logos['nucleate'],
        link: 'https://www.nucleate.xyz/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "3",
        name: 'OpenBioML',
        logo: logos['open-bio-ml'],
        link: 'https://www.openbioml.org/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "4",
        name: 'Enveda Biosciences',
        logo: logos['enveda'],
        logoWhite: logos['enveda-white'],
        link: 'https://www.envedabio.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "5",
        name: 'Recursion Pharmaceuticals',
        logo: logos['recursion'],
        logoWhite: logos['recursion-white'],
        link: 'https://www.recursion.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "6",
        name: 'Entrepreneur First',
        logo: logos['entrepreneur-first'],
        link: 'https://www.joinef.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "7",
        name: "OpenAI",
        //logo: logos['openai'],
        logo: logos['openai'],
        logoWhite: logos['openai-white'],
        link: 'https://openai.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "8",
        name: 'Geltor',
        logo: logos['geltor'],
        link: 'https://geltor.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "9",
        name: 'Not Boring',
        logo: logos['not-boring'],
        link: 'https://www.notboring.co/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "10",
        name: 'Compound',
        logo: logos['compound'],
        link: '',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "11",
        name: 'Fitbiomics',
        logo: logos['fitbiomics'],
        link: 'https://fitbiomics.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "12",
        name: 'KdT Ventures',
        logo: logos['kdt-ventures'],
        link: 'https://www.kdtvc.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "13",
        name: 'SynBioBeta',
        logo: logos['synbiobeta'],
        logoWhite: logos['synbiobeta-white'],
        link: 'https://www.synbiobeta.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "14",
        name: 'Asimov Press',
        logo: logos['asimov-press'],
        link: 'https://press.asimov.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "15",
        name: 'Fifty Years',
        logo: logos['fifty-years'],
        link: 'https://www.fiftyyears.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "16",
        name: 'LatchBio',
        logo: logos['latchbio'],
        logoWhite: logos['latchbio-white'],
        link: 'http://www.latch.bio/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "17",
        name: 'EvolutionaryScale',
        logo: logos['evolutionary-scale'],
        logoWhite: logos['evolutionary-scale-white'],
        link: 'https://www.evolutionaryscale.ai/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "18",
        name: 'RunPod',
        logo: logos['runpod'],
        link: 'https://www.runpod.io/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }
    , {
        id: "19",
        name: 'Lambda Labs',
        logo: logos['lambda-labs'],
        link: 'https://lambdalabs.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }
    , {
        id: "20",
        name: 'AWS',
        logo: logos['aws'],
        logoWhite: logos['aws-white'],
        link: 'https://aws.amazon.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "21",
        name: 'Chroma',
        logo: logos['chroma'],
        link: 'https://www.trychroma.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "22",
        name: 'Nomic',
        logo: logos['nomic'],
        link: 'https://www.nomic.ai/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }
    , {
        id: "23",
        name: 'Vercel',
        logo: logos['vercel'],
        link: 'https://vercel.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "24",
        name: 'Anthropic',
        logo: logos['anthropic'],
        link: 'https://www.anthropic.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "25",
        name: 'Modal',
        logo: logos['modal'],
        link: 'https://modal.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "26",
        name: 'Century of Biology',
        logo: logos['century-of-biology'],
        link: 'https://centuryofbio.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "27",
        name: 'Together AI',
        logo: logos['together-ai'],
        link: 'https://www.together.ai/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "28",
        name: 'DigitalOcean',
        logo: logos['digitalocean'],
        link: 'https://www.digitalocean.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "29",
        name: 'NVIDIA',
        logo: logos['nvidia'],
        link: 'https://www.nvidia.com',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "30",
        name: 'Gingko Bioworks',
        logo: logos['gingko'],
        logoWhite: logos['gingko-white'],
        link: 'https://www.ginkgobioworks.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "30",
        name: 'Light Bio',
        logo: logos['light-bio'],
        logoWhite: logos['light-bio-white'],
        link: 'https://www.light.bio/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "31",
        name: 'BioPharmaTrend',
        logo: logos['bio-pharma-trend'],
        logoWhite: logos['bio-pharma-trend-white'],
        link: 'https://www.biopharmatrend.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "32",
        name: 'Adaptyv Bio',
        logo: logos['adaptyv-bio'],
        logoWhite: logos['adaptyv-bio-white'],
        link: 'https://www.adaptyvbio.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "33",
        name: 'Polaris',
        logo: logos['polaris'],
        logoWhite: logos['polaris-white'],
        link: 'https://polarishub.io/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }, {
        id: "34",
        name: 'Scale Medicine',
        logo: logos['scale-medicine'],
        logoWhite: logos['scale-medicine-white'],
        link: 'https://www.scalemedicine.com/',
        info: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt sem eget porta rhoncus. Aenean placerat semper massa sed bibendum.
            Aliquam ac enim vitae orci cursus placerat sit amet ut arcu. Nunc sagittis, dui quis tristique faucibus, massa diam cursus dui, vitae
            placerat nisl leo ut libero. Praesent quis molestie diam. In accumsan fringilla sem. Praesent turpis nunc, viverra sit amet eleifend
            sit amet, porttitor eget velit.`,
    }]


export default companies;